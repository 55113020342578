import { mount, shallowMount } from '@vue/test-utils';
import AddPaymentMethod from './AddPaymentMethod.vue';

import PaymentProviderComponents from './paymentMethodsProviders/PaymentProviderComponents';

import { STAGE } from './constants/paymentMethodsHub';

jest.mock('./paymentMethodsProviders/PaymentProviderComponents', () => ({
  providerAddPaymentMethod: () => jest.fn(),
  providerUtils: () => jest.fn(),
}));

let wrapper;

describe('Given AddPaymentMethod component', () => {
  beforeEach(async () => {
    jest.resetModules();

    wrapper = shallowMount(AddPaymentMethod, {
      propsData: {
        locale: 'es_ES',
        provider: 'AdyenTest/3.13.0',
        userUuid: 'test-uuid',
      },
    });
  });

  afterEach(() => {
    jest.resetAllMocks();
  });

  describe('When implemented with "AdyenTest/3.15.0" as a provider', () => {
    it('Then should call "providerAddPaymentMethod" with "AdyenTest/3.15.0"', () => {
      const providerAddPaymentMethodSpy = jest.spyOn(PaymentProviderComponents, 'providerAddPaymentMethod');

      wrapper = mount(AddPaymentMethod, {
        propsData: {
          locale: 'es_ES',
          isEmbedded: false,
          isOpenModal: true,
          provider: 'AdyenTest/3.15.0',
          userUuid: 'test-uuid',
        },
      });

      expect(providerAddPaymentMethodSpy).toHaveBeenCalledWith('AdyenTest/3.15.0');
    });
  });

  describe('When the "onProviderCandidatePaymentMethod" method is called with "update:candidate-payment-method"', () => {
    it('Then the "update:candidate-payment-method" event must be emitted', () => {
      const candidatePaymentMethodTest = {
        isValid: true,
        paymentMethod: 'candidate-payment-method',
      };
      wrapper.vm.onProviderCandidatePaymentMethod(candidatePaymentMethodTest);

      expect(wrapper.emitted('update:candidate-payment-method')[0][0]).toEqual({
        isValid: true,
        paymentMethod: 'candidate-payment-method',
      });
    });
  });

  describe('When "onError" method is called with "test-error"', () => {
    it('Then the "on:error" event must be emitted with "test-error" value', () => {
      wrapper.vm.onError('test-error');

      expect(wrapper.emitted('on:error')[0][0]).toEqual('test-error');
    });
  });

  describe('When the "onCancel" method is called', () => {
    it('Then the "on:close" event must be emitted', () => {
      wrapper.vm.onCancel();

      expect(wrapper.emitted('on:close')).toBeTruthy();
    });
  });

  describe('When the "onSubmit" method is called', () => {
    it('Then the "on:submit" event must be emitted', () => {
      wrapper.vm.onSubmit();

      expect(wrapper.emitted('on:submit')).toBeTruthy();
    });
  });

  describe('When a "submitting" stage is given', () => {
    it('Then isLoading and isDisabled must be true', async () => {
      await wrapper.setProps({ stage: STAGE.submitting });

      expect(wrapper.vm.isLoading).toBeTrue();
      expect(wrapper.vm.isDisabled).toBeTrue();
    });
  });

  describe('When a "submitted" stage is given', () => {
    it('Then isLoading must be false and isDisabled must be true', async () => {
      await wrapper.setProps({ stage: STAGE.submitted });

      expect(wrapper.vm.isLoading).toBeFalse();
      expect(wrapper.vm.isDisabled).toBeTrue();
    });
  });

  describe('When a "editable" stage is given', () => {
    it('Then isLoading and isDisabled must be false', async () => {
      await wrapper.setProps({ stage: STAGE.editable });

      expect(wrapper.vm.isLoading).toBeFalse();
      expect(wrapper.vm.isDisabled).toBeFalse();
    });
  });

  describe('When a "refused" stage is given', () => {
    it('Then isLoading and isDisabled must be false', async () => {
      await wrapper.setProps({ stage: STAGE.refused });

      expect(wrapper.vm.isLoading).toBeFalse();
      expect(wrapper.vm.isDisabled).toBeFalse();
    });
  });

  describe('When a "threeDS2Challenge" stage is given', () => {
    it('Then isLoading and isDisabled must be false', async () => {
      await wrapper.setProps({ stage: STAGE.threeDS2Challenge });

      expect(wrapper.vm.isLoading).toBeFalse();
      expect(wrapper.vm.isDisabled).toBeTrue();
    });
  });
});
